<template>
  <div id="app">
    <header>
      <img id="logo" alt="#bonheur" src="./assets/logo.png">
    </header>
    <div class="container">
      <div class="content">
        <carousel
          v-if="loading === false && result == null"
          :per-page="1"
          :navigate-to="currentPage"
          :mouse-drag="false"
          :pagination-enabled="false"
          @page-change="(page) => { currentPage = page }"
        >
          <slide v-for="question in filteredQuestions" :key="`question-${question.id}`">
            <div class="question-wrapper">
              <div class="item-question">{{ question.question }}</div>
              <div v-if="question.description" class="item-description">{{ question.description }}</div>
              <FormInputText
                v-if="question.type === 'text'"
                v-model="question.answer"
                :type="question.inputType"
                :name="`question-${question.id}`"
              />
              <FormInputRadio
                v-if="question.type === 'radio'"
                :options="question.options"
                v-model="question.answer"
                @change="next()"
              />
              <FormInputCheckbox
                v-if="question.type === 'checkbox'"
                :options="question.options"
                v-model="question.answer"
              />

              <FormButton
                v-if="question.type !== 'radio'"
                :disabled="canGoForward === false"
                @click="next()"
              >Suivant →</FormButton>
            </div>
          </slide>
        </carousel>

        <div v-if="loading === false && result != null" class="result">
          <div class="result-summary box">
            <h1>Merci, voici votre prescription holistique.</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae pulvinar odio. Ut in placerat turpis, nec molestie justo. Morbi vestibulum dui suscipit urna rutrum, et condimentum nibh convallis. Sed et enim in quam vulputate dictum sed dapibus lorem. Ut et iaculis diam. Quisque sit amet faucibus elit. Vestibulum enim eros, sodales at ligula eget, luctus rhoncus ligula. Vestibulum laoreet lacus eu ullamcorper dictum.</p>
            <p>Cras sodales massa ut justo feugiat posuere. Suspendisse ornare vulputate quam eu auctor. Fusce nec eleifend ex, at sodales mauris. Curabitur risus metus, aliquam eu volutpat at, rhoncus non tortor. Aliquam fringilla, velit non faucibus facilisis, augue augue volutpat nibh, dictum consectetur libero dui quis risus. Nunc faucibus eros sed nisi iaculis pharetra. Aliquam et turpis porttitor, tempor est ac, dignissim purus. Suspendisse vehicula, metus at dictum finibus, metus lorem elementum sem, ac pretium mauris nibh ultricies metus. Fusce porta condimentum ex, sit amet finibus eros euismod sed. Etiam facilisis, odio vitae imperdiet porttitor, nibh nibh lacinia nibh, ac dictum sapien sem vitae enim. In ornare justo risus, aliquam ullamcorper elit iaculis ut. Nulla rhoncus lacus posuere massa vulputate, et pretium ligula hendrerit.</p>
          </div>
          <div class="result-row">
            <div class="products">
              <h2>Produit recommandé</h2>
              <div v-for="product in result.products" :key="`product-${product.id}`" class="product box">
                <div class="product-image">
                  <img :src="product.image" />
                </div>
                <div class="product-details">
                  <div class="product-name">{{ product.name }}</div>
                  <div class="product-content" v-html="product.content"></div>
                  <!-- <div class="product-price">{{ product.price }}</div> -->
                  <a class="product-button" :href="product.url" target="_blank">En savoir plus</a>
                </div>
              </div>
            </div>

            <div class="suggestions">
              <h2>Recommandations</h2>
              <div v-for="(suggestion, sIndex) in result.suggestions" :key="`suggestion-${sIndex}`" class="suggestion box">
                <div class="suggestion-text" v-html="suggestion"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <footer v-if="loading === false && result == null">
      <div class="progress-container">
        <div class="progress-text">{{ progress }}% complété</div>
        <div class="progress-bar">
          <div class="progress" :style="{'width': `${progress}%`}"></div>
        </div>
      </div>
      <div class="navigation-buttons">
        <button
          class="navigation-previous"
          @click="previous()"
          :disabled="currentPage <= 0"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="ionicon" viewBox="0 0 512 512"><title>Chevron Back</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M328 112L184 256l144 144"/></svg>
        </button>
        <button
          class="navigation-next"
          @click="next()"
          :disabled="canGoForward === false"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="ionicon" viewBox="0 0 512 512"><title>Chevron Forward</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M184 112l144 144-144 144"/></svg>
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import FormButton from './components/FormButton';
import FormInputText from './components/FormInputText';
import FormInputRadio from './components/FormInputRadio';
import FormInputCheckbox from './components/FormInputCheckbox';

const questions = [
  {
    id: 1,
    type: "text",
    inputType: "text",
    question: "Quel est votre prénom ?",
    description: "",
    answer: null,
  },
  {
    id: 2,
    type: "text",
    inputType: "date",
    question: "Quelle est votre date de naissance ?",
    description: "",
    answer: null,
  },
  {
    id: 3,
    type: "text",
    inputType: "email",
    question: "Quel est votre mail ?",
    description: "",
    answer: null,
  },
  {
    id: 4,
    type: "radio",
    question: "Quelle est votre préoccupation bien-être principale ?",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: 5, text: "Booster mon immunité"},
      {id:2, addition: null, subtraction: 5, text: "Apaiser mon stress "},
      {id:3, addition: null, subtraction: 5, text: "Traiter un problème de peau (acné, psoriasis, rosacée, secheresse, rougeurs ...) "},
      {id:4, addition: null, subtraction: 5, text: "Préserver ma peau du vieillissement "},
      {id:5, addition: null, subtraction: 8, text: "Améliorer ma digestion / mon confort intestinal "},
      {id:6, addition: null, subtraction: 5, text: "Mieux dormir / Être moins fatigué.e"},
      {id:7, addition: null, subtraction: 5, text: "J'ai simplement envie de prendre soin de moi "},
    ]
  },
  {
    id: 5,
    type: "radio",
    question: "Avez-vous été sous antibiotiques ces derniers mois ?",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "OUI"},
      {id:2, addition: null, subtraction: null, text: "NON"},
    ]
  },
  {
    id: 6,
    type: "radio",
    question: "Quelle est votre préoccupation de peau principale ?",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "Déshydratation "},
      {id:2, addition: null, subtraction: null, text: "Sensibilité et rougeurs "},
      {id:3, addition: null, subtraction: null, text: "Dermatites"},
      {id:4, addition: null, subtraction: null, text: "Imperfections, pores dilatés et points noirs "},
      {id:5, addition: null, subtraction: null, text: "Sècheresse"},
      {id:6, addition: null, subtraction: null, text: "Perte de fermeté "},
      {id:7, addition: null, subtraction: null, text: "Manque d'éclat et uniformité du teint "},
      {id:8, addition: null, subtraction: null, text: "Premières rides "},
      {id:9, addition: null, subtraction: null, text: "Rides visibles"},
    ]
  },
  {
    id: 7,
    type: "radio",
    question: "Quelle est votre préoccupation de peau secondaire ?",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "Déshydratation "},
      {id:2, addition: null, subtraction: null, text: "Sensibilité et rougeurs "},
      {id:3, addition: null, subtraction: null, text: "Dermatites"},
      {id:4, addition: null, subtraction: null, text: "Imperfections, pores dilatés et points noirs "},
      {id:5, addition: null, subtraction: null, text: "Sècheresse"},
      {id:6, addition: null, subtraction: null, text: "Perte de fermeté "},
      {id:7, addition: null, subtraction: null, text: "Manque d'éclat et uniformité du teint "},
      {id:8, addition: null, subtraction: null, text: "Premières rides "},
      {id:9, addition: null, subtraction: null, text: "Rides visibles"},
    ]
  },
  {
    id: 8,
    type: "radio",
    question: "Comment décririez vous votre digestion ?",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "Digestion fluide, je vais à la selle tous les jours"},
      {id:2, addition: null, subtraction: null, text: "Digestion complexe ou problèmes ponctuels  : j'ai parfois / souvent un coup de barre après le repas, j'ai des constipations, des ballonnements…"},
    ]
  },
  {
    id: 9,
    type: "radio",
    question: "Comment définiriez-vous votre alimentation ?",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: 10, text: "Je mange sain et équilibré, je mange un peu de tout, des aliments frais et de saison "},
      {id:2, addition: null, subtraction: 10, text: "Je suis un epicurien, j'aime me faire plaisir, je fais plus de 3 restaurants par semaine "},
      {id:3, addition: null, subtraction: null, text: "J'enchaîne les regimes de tout genre et je fais l'effet yoyo"},
    ]
  },
  {
    id: 10,
    type: "radio",
    question: "Quel type de régime avez-vous récemment suivi ? ",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "Hyperprotéiné"},
      {id:2, addition: null, subtraction: null, text: "Cétogène "},
      {id:3, addition: null, subtraction: null, text: "Paléo"},
      {id:4, addition: null, subtraction: null, text: "Gluten free"},
      {id:5, addition: null, subtraction: null, text: "Dukan"},
    ]
  },
  {
    id: 11,
    type: "checkbox",
    question: "Si je fais des écarts c'est plutôt...",
    description: "(Plusieurs choix possibles)",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "Avec du sucré... difficile de m'arreter ! "},
      {id:2, addition: null, subtraction: null, text: "Avec des fast food, des plats préparés ... je manque de temps pour cuisiner ! "},
      {id:3, addition: null, subtraction: null, text: "Avec l'alcool, les apéros ... les soirées c'est mon dada !"},
      {id:4, addition: null, subtraction: null, text: "Avec le café, les excitants… "},
      {id:5, addition: null, subtraction: null, text: "Je fais très attention et ne fais que très peu d'excès "},
    ]
  },
  {
    id: 12,
    type: "radio",
    question: "Ma consommation d'eau quotidienne est de : ",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "Plus d'1,5 L "},
      {id:2, addition: null, subtraction: null, text: "Moins d'1,5 L "},
    ]
  },
  {
    id: 13,
    type: "radio",
    question: "Mon sommeil est plutôt : ",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "Optimal,  je suis réglée comme une horloge et me sens en pleine forme au reveil, prêt.e à attaquer la journée "},
      {id:2, addition: null, subtraction: null, text: "Correct, il m'arrive de me reveiller la nuit de manière sucinte mais je me rendors très rapidement et je suis en forme au reveil "},
      {id:3, addition: null, subtraction: null, text: "Insuffisant, je dors peu  et / ou  je recupère mal et / ou je me reveille plusieurs fois la nuit sans arriver à me rendormir et / ou je suis épuisée au reveil "},
      {id:4, addition: null, subtraction: null, text: "Difficile et perturbé, je fais régulièrement des insomnies et / ou cauchemars et/ ou j'ai des troubles chroniques du sommeil et / ou mon sommeil est très sequencé"},
    ]
  },
  {
    id: 14,
    type: "radio",
    question: "Je fais de l'activité physique ",
    description: "",
    answer: null,
    options: [
      {id:1, addition: null, subtraction: null, text: "Très régulièrement minimum 3 fois par semaine "},
      {id:2, addition: null, subtraction: null, text: "Une fois par semaine"},
      {id:3, addition: null, subtraction: null, text: "Occasionnellement, j'ai du mal à être régulière "},
      {id:4, addition: null, subtraction: null, text: "Quasiment jamais "},
      {id:5, addition: null, subtraction: null, text: "Pas en ce moment, je suis bléssée "},
    ]
  },
];

const result = {
  products: [
    { id: 1, name: "L'huile démaquillante", content: "<p>Une huile démaquillante universelle fluide et facile à rincer qui comprend intégralement le maquillage du visage et des yeux (même waterproof), les traces de pollution et l'excès de sébum tout en entraînant la peau propre, souple et confortable. La synergie d'huiles végétales convient à toutes les peaux et tous les yeux, y compris les plus sensibles.</p>", price: 30.00, image: "https://cdn.shopify.com/s/files/1/0625/5126/1360/products/Huiledemaquillante.jpg?v=1662112512", url: "https://hashtagbonheur.com/products/lhuile-demaquillante" },
    { id: 2, name: "Les gélules équilibre jour", content: "<p>Soin signature de la marque, le soin équilibre est hautement concentré en probiotiques pour pallier les dysfonctionnements digestifs qui se cachent derrière tout problème de peau.</p>", price: 30.00, image: "https://cdn.shopify.com/s/files/1/0625/5126/1360/products/Gelulesjour.jpg?v=1662367881", url: "https://hashtagbonheur.com/products/les-gelules-equilibre-jour" },
    { id: 3, name: "La crème hydratante", content: "<p>Une crème ultra-hydratante, riche en probiotiques, qui recharge la peau en eau et stimule le renouvellement cellulaire. Ce soin léger et onctueux apaise toutes les peaux sans exception, tout en procurant douceur et confort. L'acide hyaluronique associé à l'extrait de rose blanche permettent d'hydrater sans alourdir pour une peau rebondie, tandis que l'aloe vera laisse la peau douce et confortable prévenant ainsi les sensations d’inconfort et les irritations. Enfin, l'extrait d'ylang-ylang, detoxifie la peau pour un teint frais et lumineux.</p>", price: 30.00, image: "https://cdn.shopify.com/s/files/1/0625/5126/1360/products/Cremehydratante.jpg?v=1662112660", url: "https://hashtagbonheur.com/products/la-creme-hydratante" },
  ],
  suggestions: [
    "<p>Le saviez-vous ? La prise d’antibiotiques n’élimine pas que les bactéries responsables des maladies, elle élimine aussi une part des bonnes bactéries qui peuplent votre microbiote intestinal.</p><p>Résultat ? Vous pouvez souffrir d’un manque de bonnes bactéries ce qui entraine un déséquilibre au niveau intestinal. Ainsi, après une prise d'antibiotiques, pensez aux probiotiques !</p>",
    "<p>Se faire plaisir est primordial ! Lorsque nous mangeons, nous vivons non seulement un plaisir sensoriel mais également souvent un plaisir social, fait de partages et de convivialité. </p><p>Manger au restaurant peut être intéressant nutritionnellement, à condition de faire de bons choix ! </p><p>Cela passe tout d’abord par le choix du restaurant. Où manger ? On privilégie les restaurants au choix le plus diversifié possible : brasserie, bistrot… qui nous permettent de manger de tout et de façon équilibrée. </p><p>Le choix du menu est aussi primordial ! Plat ? Entrée, plat ? Entrée, plat, dessert ? La meilleure option serait de partir sur un plat unique. Mais n’oublions pas la notion de plaisir ! Pourquoi ne pas partager une entrée et un dessert avec les autres convives ? On mange en quantité raisonnable tout en se faisant plaisir ! </p><p>Enfin, le choix de l’assiette ! Poisson blanc et légumes à l’eau ? Pâtes carbonara ? Un juste milieu existe ! L’objectif est de conserver une assiette faite de légumes, de protéines et de féculents. Un tartare de saumon avec quelques frites et de la salade par exemple ! Ou alors un poulet et des légumes au basilic avec un bol de riz. </p>",
    "<p>Le régime sans gluten est apparu à la base dans le cadre de la maladie cœliaque. Il s’agit d’une réelle intolérance au gluten et sa consommation entraîne une disparition des villosités intestinales.  </p><p>Le régime sans gluten est devenu à la mode depuis quelques années et le « gluten free » devient de plus en plus régulier sur nos emballages alimentaires ! </p><p>Mais la question est simple : pourquoi se priver de gluten quand on n’y est pas intolérant ? 😊</p>",
    "<p>Certains alcools sont à privilégier par rapport à d’autre. Le vin et le champagne sont les alcools les moins sucrés et moins calorique. Le vin rouge présente même un intérêt ! Il contient des tannins qui sont d’importants anti-oxydants. </p><p>Evitez surtout les alcools forts, ils sont très riches en sucre et souvent associés à des boissons sucrées ! Un verre de vin est préférable à une pina colada 😉 </p><p>N’oubliez pas que l’alcool n’est pas de l’eau ! Les boissons alcoolisées ont même tendance à déshydrater. Une règle simple pour éviter la déshydrations en soirée serait de boire un grand verre d’eau entre chaque verre d’alcool. </p>",
    "<p>Le saviez-vous ? L’eau est le principal constituant du corps humain ! L’homme adulte est constitué à 65% d’eau, ce qui correspond à 45 litres d’eau pour une personne de 70kgs.</p><p>N’oubliez pas que l’eau sert à : hydrater la peau et la rendre plus belle, prévenir les maux de tête, faciliter le transit, éliminer les toxines, perdre du poids, améliorer la digestion si elle est bue avant un repas etc.</p>",
    "<p>Vous avez du mal à être régulière dans vos séances. Rassurez-vous, vous n’êtes pas la seule !</p><p>Pour la plupart des personnes, le moyen le plus simple de bouger est d'intégrer une activité à la vie quotidienne. Avez-vous déjà pensé à marcher ou faire du vélo au lieu d'utiliser la voiture pour vous déplacer ? Pensez-vous à toujours prendre les escaliers plutôt que l’ascenseur ? </p>"
  ],
};

export default {
  name: 'App',
  components: {
    Carousel, Slide, FormButton, FormInputText, FormInputRadio,
    FormInputCheckbox,
  },
  data() {
    return {
      questions,
      loading: false,
      result: null,
      currentPage: 0,
      conditions: [
        {
          index: 4,
          qindex: 3,
          aid: 5,
          condition: 'equal',
        },
        {
          index: 7,
          qindex: 3,
          aid: 5,
          condition: 'not-equal',
        },
        {
          index: 9,
          qindex: 3,
          aid: 5,
          condition: 'equal',
        },
      ]
    };
  },
  computed: {
    progress() {
      return Math.round(this.currentPage / (this.filteredQuestions.length || 1) * 100);
    },
    canGoForward() {
      return this.filteredQuestions[this.currentPage].answer != null
        && this.filteredQuestions[this.currentPage].answer != ''
        && this.filteredQuestions[this.currentPage].answer.length != [];
    },
    filteredQuestions() {
      const subtractions = [];
      this.questions.map((q) => {
        if (q.type === 'radio' && q.answer != null && q.answer.subtraction != null) {
          subtractions.push(q.answer.subtraction);
        }
      });

      return this.questions.filter((q) => !subtractions.includes(q.id));
    },
  },
  methods: {
    previous() {
      if (this.currentPage <= 0) {
        return false;
      }
      this.currentPage -= 1;
    },
    next() {
      if (
        this.filteredQuestions[this.currentPage].answer == null
        || this.filteredQuestions[this.currentPage].answer == ''
        || this.filteredQuestions[this.currentPage].answer.length === []
      ) {
        return false;
      }
      if (this.currentPage === this.filteredQuestions.length - 1) {
        this.result = result;
      } else {
        this.currentPage += 1;
      }
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

html, body {
  margin: 0;
  /* height: 100%; */
}
body {
  background-color: #F7EEDD;
}
#app {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #FFFFFF;
  height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#logo {
  height: 2.5rem;
}

.container {
  height: 100vh;
}

.content {
  padding: 6rem 0;
}

.VueCarousel-slide {
  min-height: calc(100vh - 12rem);
  display: flex;
  justify-content: center;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  background-color: #FFFFFF;
  padding: 0 1rem;
}

footer .progress-container {
  flex: 1;
  padding-right: 1rem;
}
.progress-container > .progress-text {
  font-weight: 500;
  font-size: 0.85rem;
  margin-bottom: 5px;
}
.progress-container > .progress-bar {
  width: 100%;
  padding: 1px;
  border: 1px solid #dddddd;
  border-radius: 7px;
}
.progress-container > .progress-bar > .progress {
  height: 5px;
  border-radius: 5px;
  background-color: #000000;
  min-width: 5px;
  transition: all 1s;
}

footer .navigation-buttons > button {
  width: 2.5rem;
  height: 2.5rem;
  color: #FFFFFF;
  background-color: #000000;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}

footer .navigation-buttons > button:hover {
  opacity: 0.8;
}

footer .navigation-buttons > button:active {
  opacity: 0.6;
}

footer .navigation-buttons > button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

footer .navigation-buttons > .navigation-previous {
  margin-right: 0.5rem;
}

.question-wrapper {
  width: 100%;
  text-align:center;
  margin:auto;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .question-wrapper {
    width: 720px;
  }
}

.item-question {
  color: #333333;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}

.item-description {
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  font-style: italic;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.result {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .result {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .result {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .result {
    width: 1170px;
  }
}

.box {
  padding: 1rem;
  background-color: #FFFFFF;
  /* border-radius: 0.5rem; */
}

.result-summary {
  padding: 3rem 1rem;
  text-align: center;
  margin-bottom: 2rem;
}

.result-summary > h1 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.result-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.result-row > div {
  width: 100%;
}

@media (min-width: 768px) {
  .result-row {
    flex-direction: row;
  }
  .result-row > div {
    width: 48%;
  }
}

.result-row h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

/* .products {
} */

.product {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.product-image {
  width: 35%;
  margin-right: 1rem;
}
.product-image > img {
  width: 100%;
}
.product-details {
  flex: 1;
}
.product-details > .product-name {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.product-details > .product-content {
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.product-details > .product-button {
  color: #FFFFFF;
  background-color: #000000;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  display: block;
  padding: 0.5rem 1.5rem;
}

/* .suggestions {
} */

.suggestion {
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}
.suggestion > .suggestion-text {
  font-size: 0.95rem;
  font-weight: 400;
}
</style>
