<template>
  <button
    :type="type"
    :name="name"
    class="form-button"
    @click="click"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'FormButton',

  methods: {
    click() {
      this.$emit('click');
    },
  },
}
</script>

<style scoped>
.form-button {
  cursor: pointer;
  color: #FFFFFF;
  background-color: #000000;
  border: none;
  padding: 1rem;
  width: 90%;
  transition: all 0.3s;
}

.form-button:hover {
  opacity: 0.8;
}

.form-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

@media (min-width: 768px) {
  .form-button {
    width: 50%;
  }
}
</style>
