<template>
  <div class="radio-container">
    <div
      v-for="(option, index) in options"
      :key="`option-${index}`"
      class="option"
      :class="{'checked': option === model}"
      @click="setValue(option)"
    >
      <div class="option-radio">
        <svg v-if="option === model" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M416 128L192 384l-96-96"/></svg>
      </div>
      <div class="option-text">{{ option.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormInputRadio',
  props: {
    value: {
      type: null,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      model: this.value,
    };
  },

  methods: {
    setValue(value) {
      this.model = value;
      this.$emit('input', value);
      this.$emit('change');
    },
  },
}
</script>

<style scoped>
.radio-container {
  width: 90%;
  margin: 2rem 5%;
}
.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.5s;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.4rem;
}
.option:hover {
  color: #FFFFFF;
  background-color: #000000;
}
.option-radio {
  width: 20px;
  height: 20px;
  border: 1px solid #CCCCCC;
  border-radius: 50%;
  color: #FFFFFF;
  background-color: #FFF2F3;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option:not(.checked):hover > .option-radio {
  width: 12px;
  height: 12px;
  border: 5px solid #666666;
  background-color: #CCCCCC;
}
.option.checked > .option-radio {
  border: 1px solid #808080;
  background-color: #808080;
}
.option-text {
  flex: 1;
  text-align: left;
}

@media (min-width: 768px) {
  .radio {
    width: 50%;
  }
}
</style>
