<template>
  <input
    :id="name"
    v-model="model"
    :type="type"
    :name="name"
    class="input-text"
    placeholder="Répondez ici..."
  />
</template>

<script>
export default {
  name: 'FormInputText',
  props: {
    value: {
      type: null,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
}
</script>

<style scoped>
.input-text {
  font-weight: 500;
  height: 42px;
  border: none;
  border-bottom: 1px solid #000000;
  font-size: 16px;
  -webkit-transition: border-bottom .25s;
  transition: border-bottom .25s;
  background-color: transparent;
  width: 90%;
  margin-bottom: 15px;
}
.input-text:focus {
  outline: none;
}

@media (min-width: 768px) {
  .input-text {
    width: 50%;
  }
}
</style>
